import React from "react";
import HomePage from "../components/layout/Home";
import TOSModal from "../components/home/terms-of-service-takeover";

export default props => {
  return (
    <HomePage>
      <TOSModal />
    </HomePage>
  );
};
